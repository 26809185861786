.usecase-content h3{
      font-size: 1.5rem;
}
.usecase-container{
width: 87.7%;
margin: auto;
text-align: left;
}
.usecase-title{
margin: 60px auto;
}
.usecase-content{
margin: 60px auto;
}
.img-text-container{
      display: flex;
      width: 100%;
      margin: 0;
     padding: 20px 0;
     border-bottom: 1px solid lightgray;
}
.img-text-container p{
      width: 100%;
}
.img-container{
      zoom:0.3;
     float: right;
}

.img-container img{
border-radius: 6px;
box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
margin-left: 6rem;

}
.text-container{
  /* padding: 30px; */
  /* margin: auto 0; */

}
.text-container span{
      font-weight: 800;
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}
.img-flex{
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 30px;
}
.img-flex img{
      width: 49%;
}

.img-flex-full{
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 30px;
}
.img-flex-full img{
      width: 100%;
}

@-moz-document url-prefix() {
      .img-container{
          width: 30%;
      }
      .img-container img{
            width: 100%;
            height: auto;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
        }
        .text-container{width: 70%;}
      
  }

@media screen and (max-width: 1800px){
      .img-container{
           zoom:0.25;
           float: right;
      }

  }
  @media screen and (max-width: 1200px){
      .big-image img{
            width: 100%;
            margin: 0;
  }
  .big-image{
      width: 100%;
}
  
}
@media screen and (max-width: 959px){
      .img-text-container{
            display: block;
      }
      .img-container{
            margin: 30px;
      float: none;
}
      .img-container img{
            width: 100%;
            margin: 0;
      }
      .img-flex{
            display: block;
      }
      .img-flex img{
            width: 100%;
      }
      @-moz-document url-prefix(){
            .img-text-container{
                  display: block;
            }
            .img-container{
                  width: 90%;}
            .img-container img{
                  width: 100%;
                  
            }
            .text-container{
                  width: 100%;
            }
      }
      
}
@media screen and (max-width: 599px){
}

.li-partition-naming {
      margin-left: 15px;
      list-style-type: disc;
}