.char-counter {
    justify-content: space-between;
    width: 99%;
    display: flex;
}

.submit-button {
    float: right;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.submit-button:hover {
    cursor: pointer;
    background: #64a6a6;
    transition: all 0.4s ease-out;
}

.spinner {
    color: #c4c4c4 !important
}

@media only screen and (max-width: 1248px) and (min-width: 860px),
(max-width: 384px) {
    .captcha-container {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}