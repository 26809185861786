body {
  display: grid;
  place-items: center;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  background-repeat: no-repeat;
  overflow-x: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PTRootUI';
  font-weight: 100;
  font-display: swap;
  src: local('PTRootUI'), url('./fonts/pt-root-ui/PT\ Root\ UI_Light.otf') format('opentype');
}

@font-face {
  font-family: 'PTRootUI';
  font-weight: 300;
  font-display: swap;
  src: local('PTRootUI'), url('./fonts/pt-root-ui/PT\ Root\ UI_Regular.otf') format('opentype');
}

@font-face {
  font-family: 'PTRootUI';
  font-weight: 500;
  font-display: swap;
  src: local('PTRootUI'), url('./fonts/pt-root-ui/PT\ Root\ UI_Medium.otf') format('opentype');
}

@font-face {
  font-family: 'PTRootUI';
  font-weight: 700;
  font-display: swap;
  src: local('PTRootUI'), url('./fonts/pt-root-ui/PT\ Root\ UI_Bold.otf') format('opentype');
}
