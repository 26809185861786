@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../../../fonts/pt-root-ui/PT\ Root\ UI_Bold.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../../../fonts/pt-root-ui/PT\ Root\ UI_Medium.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

.featureContainer {
    width: 100%;
}

.featureTitle {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: #64a6a6;
}

.featureTitleText {
    width: 40%;
    margin: auto;
}

.featureTitle h2 {
    font-family: "PTRootUIWebBold", sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
}

.featureTitle p {
    color: white;
    line-height: 1.4rem;
}

.featurePage h2 {
    color: #000;
    /* color:#315a5e; */
    font-size: 2rem;
    font-family: "PTRootUIWebMedium", sans-serif;
    text-align: left;
}

.titleContainer {
    margin-top: 20px;
}

.featureText h1 {
    color: #315a5e;
    /* color:black; */
    /* color:#64a6a6; */
    font-size: 2.2rem;
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 0;
    margin-bottom: 36px;
    text-align: left;
    line-height: 40px;
}

.featureText p {
  color: #5a5a5a;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
}

.featurePage {
  padding-bottom: 40px;
}
.featurePageContent{
  padding-top: 40px;
}

.feature1 {
  display: block;
  margin-top: 48px;
  height: 280px;
  position: relative;
}

.featureText {
  width: 45%;
  position: absolute;
  bottom: 20px;
}

.feature1 img {
  width: 45%;
  min-width: 320px;
  right: 0;
  bottom: 0;
  position: absolute;
}

.featureText p {
  padding-right: 20px;
  padding-bottom: 20px;
  border-right: 1px #64a6a6 solid;
  border-bottom: 1px #64a6a6 solid;
}

.feature2 {
  display: block;
  margin-top: 12%;
  height: 280px;
  position: relative;
}

.feature2 img {
  width: 45%;
  min-width: 320px;
  left: 0;
  bottom: 0;
  position: absolute;
}

.feature2 .featureText {
    right: 0;
}

.Button {
  width: 180px;
  /* height: 56px;  */
  /* border: 2px #c4c4c4 solid; */
  background-color: rgba(225,225,225,0.25);
  font-size: 24px;
  color: white;
  padding: 10px 0;
  margin: auto;
}

.Button:hover{
  background-color: white;
  color:#ed6a5a;
  /* border: 2px #ed6a5a solid; */
  box-shadow: 0 0 20px rgba(0,0,0,0.25);
}

.btn-cont .btn {
  position: relative;
  padding: 12px 50px;
  border: 1px solid #c4c4c4;
  color: white;
  text-decoration: none;
  font-size: 1.25em;
  /* font-family: "Open Sans", sans-serif; */
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
}

.btn-cont .btn:hover {
  border: none;
  text-decoration: none;
}

.btn-cont .btn:hover .line-1 {
  -webkit-animation: move1 1500ms infinite ease;
          animation: move1 1500ms infinite ease;
}

.btn-cont .btn:hover .line-2 {
  -webkit-animation: move2 1500ms infinite ease;
          animation: move2 1500ms infinite ease;
}

.btn-cont .btn:hover .line-3 {
  -webkit-animation: move3 1500ms infinite ease;
          animation: move3 1500ms infinite ease;
}

.btn-cont .btn:hover .line-4 {
  -webkit-animation: move4 1500ms infinite ease;
          animation: move4 1500ms infinite ease;
}

.btn-cont .line-1 {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  background-color: white;
  left: 0;
  bottom: 0;
}

.btn-cont .line-2 {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  background-color: white;
  left: 0;
  top: 0;
}

.btn-cont .line-3 {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  background-color: white;
  right: 0;
  top: 0;
}

.btn-cont .line-4 {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  background-color: white;
  right: 0;
  bottom: 0;
}

@-webkit-keyframes move1 {
  0% {
    height: 100%;
    bottom: 0;
  }
  54% {
    height: 0;
    bottom: 100%;
  }
  55% {
    height: 0;
    bottom: 0;
  }
  100% {
    height: 100%;
    bottom: 0;
  }
}

@keyframes move1 {
  0% {
    height: 100%;
    bottom: 0;
  }
  54% {
    height: 0;
    bottom: 100%;
  }
  55% {
    height: 0;
    bottom: 0;
  }
  100% {
    height: 100%;
    bottom: 0;
  }
}
@-webkit-keyframes move2 {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes move2 {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@-webkit-keyframes move3 {
  0% {
    height: 100%;
    top: 0;
  }
  54% {
    height: 0;
    top: 100%;
  }
  55% {
    height: 0;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}
@keyframes move3 {
  0% {
    height: 100%;
    top: 0;
  }
  54% {
    height: 0;
    top: 100%;
  }
  55% {
    height: 0;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}
@-webkit-keyframes move4 {
  0% {
    width: 0;
    right: 0;
  }
  55% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}
@keyframes move4 {
  0% {
    width: 0;
    right: 0;
  }
  55% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}

@media screen and (max-width: 959px) {
  .featureTitleText {
    width: 50%;
  }
  .featurePage {
    height: auto;
    background-size: 160%;
    padding: 0;
  }
  .featureText {
    width: 100%;
    position: relative;
    margin-top: 40px;
  }
  .feature1 img {
    width: 100%;
    float: none;
    margin: auto;
    top: 1%;
    position: relative;
  }
  .feature1 {
    height: 500px;
    margin-bottom: 15%;
  }
  .feature2 {
    height: 500px;
    margin-top: 0;
    margin-bottom: 15%;
  }
  .feature2 img {
    width: 100%;
    float: none;
    margin: auto;
    top: 1%;
    position: relative;
  }
}

@media screen and (max-width: 599px) {
  .featureTitleText {
    width: 90%;
  }
  .featurePage {
    width: 90%;
    margin: auto;
    background-size: 200%;
  }
  .feature1 {
    margin-bottom: 0;
  }
  .feature2 {
    margin-bottom: 5%;
  }
}